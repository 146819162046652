import React, { Fragment } from "react";

//import { faBitcoin } from "@fortawesome/fontawesome-svg-core";
import { faCoins} from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Breadcrumb from "../component/common/breadcrumb/breadcrumb";
import {
  Container,
  Col,
  Row,
  Form,
  FormGroup,
  Card,
  CardBody,
  Label,
  Button,
  Input,
  CardHeader,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  
} from "reactstrap";

const TokenContract = (props) => {
  React.useEffect(() => {
    document.body.classList.toggle("home");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("home");
    };
  },[]);
  return (
    <>
    <Fragment>
      <Container fluid={true}>
      <Breadcrumb parent="Forms / Form Controls" title="" />
        <div className="page-header">
          
          <div className="pl-md-5">
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="6" md="6">
                <h1 className="text-white">
                  Bigpadsale <br />
                  <span className="text-white">Multichain decentralized launchpad</span>
                </h1>
                <p >
                  Bigpadsale is a multichain launchpad for users and project owners designed to help launch their projects and tokens in the easiest way. We have made
evm compatible platform, so it can run on multiple networks and provides users with many features. With a high level of security and and very low launch costs.
                </p>
                <div className="btn-wrapper mb-3">
                  <p className="category text-success d-inline">
                    Create sale fee 0.004 NINJA/presale
                  </p>
                  
                </div>
                <div className="btn-wrapper">
                  <div className="button-container">
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-dribbble" />
                    </Button>
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-facebook" />
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg="4" md="5">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("../assets/images/logo/two.gif")}
                />
              </Col>
            </Row>
          </div>
        </div>
      
        <section className="section section-lg">
          <section className="section">
            
            <Container>
              <Row className="row-grid justify-content-between">
                <Col className="mt-lg-5" md="5">
                  <Row>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      
                    </Col>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      
                    </Col>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <h1>
                       <br />
                      Evm compatible 
                    </h1>
                    <p>
                      EVM compatibility on bigpadsale means creating a launchpad environment that can run on a blockchain based on EVM, which makes it easy for developers to launch their token projects with smart contracts to bigpadsale at a very low cost.
                    </p>
                    <br />
                    <p>
                      Lets take the example of the Binance Smart Chain network. On average, on the current launchpad it takes 1 BNB to make a presale. While on Bigpadsale it only takes starting from 0.004 to make a presale.
                    </p>
                    <br />                    
                    <a href="https://bigpadsale.gitbook.io/bigpadsale/create-sale/create-sale-on-bigpadsale-launchpad" target="_blank">
                    <Button color="primary" className="mr-1">Create sale documentation</Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </section>

        
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <section className="section section-lg">
          
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="text-center"></h1>
                <Row className="row-grid justify-content-center">
                  <Col lg="3">
                    <div className="info">
                      <div className="">
                        <FontAwesomeIcon icon={faCoins} size="10x" /> 

                        <i className="tim-icons icon-money-coins" />
                      </div>
                      <br/>
                      
                      <h4 className="info-title">Low cost fee</h4>
                      <hr className="line-primary" />
                      <p>
                        As explained above, it is very cheap to make presale contracts on bigpadsale, starting from 0.04 coins. And with the flexibility of the soft cap, the presale here has a very big chance to succeed. Our interface is also simple and easy to use. You can experiment with testnet network first.
                      </p>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-warning">
                        <FontAwesomeIcon icon={faCubes} size="10x" /> 
                        <i className="tim-icons icon-chart-pie-36" />
                      </div>
                      <br/>
                      <h4 className="info-title">Secured launchpad</h4>
                      <hr className="line-warning" />
                      <p>
                        We create a safe system in our environment. So on bigpadsale, all presales must automatically be listed on the appropriate Dex. Otherwise, the collected funds will not be able to be taken by the presale maker and this makes investors have certainty and security that their funds will not be taken after the presale ends.
                      </p>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-success">
                        <FontAwesomeIcon icon={faCheck} size="10x" />
                        <i className="tim-icons icon-single-02" />
                      </div>
                      <br/>
                      <h4 className="info-title">Verified people</h4>
                      <hr className="line-success" />
                      <p>
                        KYC or Know Your Customer is a practice carried out to verify project identity, on bigpadsale KYC is optional and if project owner do KYC they will get many benefits and trust, we have a special program for those who do KYC including marketing campaigns and others. We will share the owner data of the project that does KYC if the project is proven to be a scam.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <section className="section section-lg section-safe">
          
          <Container>
            <Row className="row-grid justify-content-between">
              <Col md="5">
               
                
              </Col>
              <Col md="6">
                <div className="px-md-5">
                  <hr className="line-success" />
                  <h3>Support</h3>
                  <p>
                    For any questions about bigpadsale you can contact us via email or direct inquiries
                  </p>
                  <ul className="list-unstyled mt-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-vector" />
                        </div>
                        <div className="ml-3">
                          <h6></h6>
                        </div>
                        <a href="mailto:admin@bigpadsale.com" target="_blank">
                      admin@bigpadsale.com
                      <i className="tim-icons icon-minimal-right text-info" />
                      </a>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-tap-02" />
                        </div>
                        <div className="ml-3">
                          <h6></h6>
                        </div>
                        <a href="https://t.me/bigpad" target="_blank">
                      https://t.me/bigpad
                      <i className="tim-icons icon-minimal-right text-info" />
                      </a>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-single-02" />
                        </div>
                        <div className="ml-3">
                          <h6></h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <br/>
        <br/>
        <br/>
        <br/>
        
            
      </Container>
    </Fragment>
    </>
  );
};

export default TokenContract;
