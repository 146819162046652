import React, { Fragment } from "react";
import Breadcrumb from "../component/common/breadcrumb/breadcrumb";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
const Harvest = (props) => {
  return (
    <Fragment>
      <Breadcrumb parent="Forms / Form Controls" title="" />
      <Container fluid={true}>
        <Row>
        <div className="page-header">
          
          <div className="content-center">
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="9" md="6">
              <img
                  alt="..."
                  className="img-fluid"

                  src={require("../assets/images/logo/rocket.gif")}
                />
                
                
              </Col>
              
            </Row>
          </div>
        </div>
          <Col sm="12">
            <Form className="form theme-form">
             <section className="section section-lg">
          <section className="section">
            
            <Container>
              <Row className="row-grid justify-content-between">
                
                <Col md="6">
                  <div className="pl-md-5">
                    <h1>
                       <br />
                      Buy Hold and Earn
                    </h1>
                    <br />
                    <p>
                      Bigpadsale tokens are tokens that automatically give rewards to their holders. This is a reflection token that stakes automatically during the buying and selling process, bigpadsale tokens apply a tax on each transaction to be redistributed in the form of bigpadsale tokens to all token holders fairly based on the amount they have.
                    </p>
                    <br />
                    <p>
                      Bigpadsale token is also an auto farm token. This means that every token holder will get USDN rewards as long as they hold the bigpadsale token. The amount of the rewards will be determined from the tax and from how much bigpadsale tokens the holder has.
                    </p>
                    <br />

                    <p>
                      The conclusion is that bigpadsale token holders will continue to see their portfolio continue to grow as long as they hold bigpadsale tokens in their wallets.
                    </p>
                    <br />
                    
                    <a href="https://bigpadsale.gitbook.io/bigpadsale/" target="_blank">
                      Bigpadsale token is COMING and now in development stage{" "}
                      <i className="tim-icons icon-minimal-right text-info" />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </section>
              
              
         <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
              <Card>
                <CardBody className="token-button">
                  
                    <a href="#" target="_blank">
                    <Button color="primary" className="mr-1">
                      Auto Rewards Token
                    </Button>
                    </a>

                    <a href="#" target="_blank">
                    <Button color="primary" className="mr-1">
                      Auto Staking Token
                    </Button>
                    </a>

                    <a href="#" target="_blank">
                    <Button color="primary" className="mr-1">
                      Auto Farm Token
                    </Button>
                    </a>
                 
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Harvest;
