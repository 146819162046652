import { ethers, BigNumber } from "ethers";
import moment from 'moment'

const decimals = "1000000000000000000";
// const CONTRACT_ADDRESSES = "0xba1aafa3c8bd15d4bd1f2426286485523c4b6523";
// const LANCHPAD_CONTRACT_ADDRESSES = "0x405FE5d99E49ac168F9892c1d0F10b177C71df56";
const BURN_ADDRESS = "0x0000000000000000000000000000000000000000";
// const MelioraInfo_CA = "0x80f14B7320E6ffe4C3E15a123e8f7E14A09aC56b";


const network = new Map();
// add as new networks 

network.set(87654321, {
                  name: "Ninjachain testnet",
                  currency: "NINJA",
                  exchange: "Swapmurai",
                  tokenTitle: "ERC-20",
                  factory_addr: "0xe9C63895112D04FccA6eb7250242C7AF8f13700E", 
                  launchpad_addr: "0x15127aE217514EEbB31999403371C31bAF101a0f",
                  info_addr: "0x83e5719a6b1F3a6e88BbF4EEe9afBb9C0Be05219"});

network.set(97, {
                name: "BSC testnet",
                currency: "BNB",
                exchange: "PancakeSwap",
                tokenTitle: "BEP-20",
                factory_addr: "0x8d1d2150c13ec129ce9d9e83941ee84de1137f73", 
                launchpad_addr: "0xD54C98d200ae879706b541943aeF966c93F5f2fF",
                info_addr: "0xB4C297DE6939997Fb207E7aa5a5AEC2A9028C8e5"})
               
               //asli factory_addr: "0x8d1d2150c13EC129ce9D9e83941EE84De1137f73", 
               // launchpad_addr: "0xD54C98d200ae879706b541943aeF966c93F5f2fF",
               // info_addr: "0xB4C297DE6939997Fb207E7aa5a5AEC2A9028C8e5"});

/* 
chanege 
factory_addr -> MeliorFactory contract 
launchpad_addr -> MelioraLaunchpad
info_addr -> MelioraInfo

*/

  

    
        
// network.set(1, {
//                 name: "Ethereum testnet",
//                 factory_addr: "0xba1aafa3c8bd15d4bd1f2426286485523c4b6523", 
//                 launchpad_addr: "0x405FE5d99E49ac168F9892c1d0F10b177C71df56",
//                 info_addr: "0x80f14B7320E6ffe4C3E15a123e8f7E14A09aC56b"})



const getCurrentNetwork = ()=>{
  if(!network.has(parseInt(localStorage.getItem("currChain"))))
  {
    throw Error("This Network is not allowed");
  }
  return network.get(parseInt(localStorage.getItem("currChain")));
}


let calculateApproveAmt = (hardCapInWei, liquidityPercentageAllocation, listingPriceInWei, tokenPriceInWei) => {
  const maxTokensToBeSold = tokenPriceInWei > 0 ? hardCapInWei / tokenPriceInWei : 0;
  const maxEthPoolTokenAmount = hardCapInWei * (liquidityPercentageAllocation / 100);
  const maxLiqPoolTokenAmount = +listingPriceInWei ? (maxEthPoolTokenAmount / listingPriceInWei) : 0;
  const requiredTokenAmount = (maxLiqPoolTokenAmount + maxTokensToBeSold);
  return requiredTokenAmount;
}


let makeData = (formData) => {
  let whiteList = formData.get('whiteList');
  let whiteListArray = whiteList != '' ? whiteList.split(" ") : [];


  const launchpadInfo = {
    tokenAddress: formData.get('tokenAddress'),
    unsoldTokensDumpAddress: formData.get('unsoldTokensDumpAddress') || BURN_ADDRESS,
    whitelistedAddresses: [],
    tokenPriceInWei: ethers.utils.parseEther(formData.get('tokenPriceInWei')),
    hardCapInWei: ethers.utils.parseEther(formData.get('hardCapInWei')),
    softCapInWei: ethers.utils.parseEther(formData.get('softCapInWei') || '0'),
    maxInvestInWei: ethers.utils.parseEther(formData.get('maxInvestInWei') || '0'),
    minInvestInWei: ethers.utils.parseEther(formData.get('minInvestInWei') || '0'),
    openTime: moment(formData.get('openTime')).unix(),
    closeTime: moment(formData.get('closeTime')).unix()
  };

  const launchpadUniswapInfo = {
    listingPriceInWei: ethers.utils.parseEther(formData.get('listingPriceInWei') || '0'),
    liquidityAddingTime: moment(formData.get('liquidityAddingTime')).unix(),
    liquidityPercentageAllocation: BigNumber.from(formData.get('liquidityPercentageAllocation')),
    lpTokensLockDurationInDays: BigNumber.from("1")
  };

  const launchpadStringInfo = {
    saleTitle: ethers.utils.formatBytes32String(formData.get('saleTitle')),
    linkTelegram: ethers.utils.formatBytes32String(formData.get('linkTelegram')),
    linkDiscord: ethers.utils.formatBytes32String(formData.get('linkDiscord')),
    linkTwitter: ethers.utils.formatBytes32String(formData.get('linkTwitter')),
    linkWebsite: ethers.utils.formatBytes32String(formData.get('linkWebsite'))
  }


  return { launchpadInfo: launchpadInfo, launchpadUniswapInfo: launchpadUniswapInfo, launchpadStringInfo: launchpadStringInfo };
}

function truncate(val) {
  return `${val.substring(0, 6)}...${val.substring(38)}`;
}

let unixTimeToUTC = (timeStamp) => {
  var dateTime = new Date(timeStamp * 1000);
  return dateTime.toUTCString();
  
  // return urn `${moment(timeStamp / 1000).format("YYYY-MM-DD hh:mm a")};
}


export {
  makeData,
  getCurrentNetwork,
  calculateApproveAmt,
  decimals,
  BURN_ADDRESS,
  truncate,
  unixTimeToUTC,
  network
  
}