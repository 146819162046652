import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../component/common/breadcrumb/breadcrumb";
import {
  Container,
  Col,
  Row,
  Form,
  FormGroup,
  Card,
  CardBody,
  Label,
  Button,
  Input
} from "reactstrap";

const TokenContract = (props) => {

  return (
    <Fragment>
      <Breadcrumb parent="Forms / Form Controls" title="" />
      <Container fluid={true}>
        <Row>
          <Col className="token-contract" md="12" xl="6">
            <Form className="form theme-form">
              

              
            </Form>
          </Col>

          <Col md="12" xl="6">
            <Card>
              <CardBody>
                <FormGroup>
                  <Row>
                    <Col>
                      <Label>√ This document is used to help users and investors to understand what bigpadsale multichain launchpad is.</Label>
                      
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <Label>√ Bigpadsale is a good opportunity for developers to introduce the crypto assets they are developing to investors. This at the same time provides an opportunity for the developer to get an injection of additional funds through the initial investment of investors
of a successful launch.</Label>
                      
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col>
                      <Label>√ For investors, this will explain how bigpadsale as a decentralized launchpad is very safe for them.</Label>
                      
                    </Col>
                  </Row>
                </FormGroup>
              </CardBody>
            </Card>

            <Card>
              <CardBody className="token-button">
                <a href="https://bigpadsale.gitbook.io/bigpadsale/" target="_blank">
                <Button color="primary" className="mr-1">Read All Documentation</Button>
                </a>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TokenContract;
