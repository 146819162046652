import Create from "../pages/create";
import Harvest from "../pages/harvest";
import Launchpads from "../pages/launchpads";
import Project from "../pages/project";
import Home from "../pages/home";
import Docs from "../pages/docs";

export const routes = [
  { path: "/create", Component: Create },
  { path: "/harvest", Component: Harvest },
  { path: "/launchpads", Component: Launchpads },
  { path: "/home", Component: Home },
  { path: "/project/:id", Component: Project },
  { path: "/docs", Component: Docs },
];
